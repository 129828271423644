import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Select } from '@bcmi-labs/react-components';
import './style.scss';
import { Helmet } from 'react-helmet';
import { navigate, useLocation } from '@reach/router';
import SEO from '../../../components/seo';
import Layout from '../../../components/layout';

const API_URL = 'https://api2.arduino.cc';

const professions = [
  { label: 'Designer / Artist', key: 'profession_designer_artist' },
  { label: 'Teacher', key: 'profession_teacher' },
  { label: 'Engineer / Developer', key: 'profession_engineer_developer' },
  { label: 'Maker', key: 'profession_maker' },
  { label: 'Student', key: 'profession_student' },
  { label: 'Entrepreneur', key: 'profession_entrepreneur' },
  { label: 'Other', key: 'profession_other' },
];

const interests = [
  { label: 'Sustainability, Social Innovation', key: 'interest_sustainability_social_innovation' },
  { label: 'Education', key: 'interest_education' },
  { label: 'Fashion and Wearables', key: 'interest_fashion_wearables' },
  { label: 'Healthcare and Fitness', key: 'interest_healthcare_fitness' },
  { label: 'Home Automation', key: 'interest_home_automation' },
  { label: 'Agriculture and gardening', key: 'interest_agriculture_gardening' },
  { label: 'Robotics', key: 'interest_robotics' },
  { label: 'Gaming', key: 'interest_gaming' },
  { label: 'Art, Music and Design', key: 'interest_art_music_design' },
  { label: 'PRO / Industrial', key: 'interest_pro_industrial' },
  { label: 'AI', key: 'interest_ai' },
];

const Survey = () => {
  const [countries, setCountries] = useState(null);
  const [surveyTags, setSurveyTags] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);

  const location = useLocation();
  const { id, email, tags } = (location || {}).state || {};

  useEffect(() => {
    fetch(`${API_URL}/countries/v1`)
      .then(response => response.json())
      .then(data => setCountries(data));
  }, []);

  const onChangeTag = (key, e) => {
    const tagsSet = new Set([...surveyTags]);
    if (e.target.checked) {
      tagsSet.add(key);
    } else {
      tagsSet.delete(key);
    }

    setSurveyTags([...tagsSet]);
  };

  const handleSubmit = () => {
    const data = { email, survey_tags: surveyTags, country: selectedCountry, tags };
    fetch(`${process.env.GATSBY_API_URL}/consent/v1/subscription/update/${id}`, {
      method: 'PUT',
      body: JSON.stringify(data),
    }).then(response => {
      navigate('/en/donate/newsletter-thank-you');
    });
  };

  return (
    <Layout currentLocale="en">
      <Helmet
        bodyAttributes={{
          class: 'newsletter-page-body',
        }}
      />
      <SEO title="Newsletter survey" />
      <main className="newsletter-page page">
        <section>
          <div className="newsletter-survey page-content">
            <h2>Tell us more!</h2>
            <p>
              We'd like to get to know you little better. Please help us improve by answering this super short optional
              survey.
            </p>
            <h4>What is your profession?</h4>
            <div className="newsletter-survey__checkbox-container" style={{ gridTemplateColumns: 'repeat(4, 1fr)' }}>
              {professions.map(({ key, label }) => (
                <Checkbox
                  className="newsletter-survey__checkbox"
                  name={key}
                  value={surveyTags.includes(key)}
                  key={key}
                  onChange={e => onChangeTag(key, e)}
                  label={label}
                />
              ))}
            </div>
            <h4>Which categories best describe your interests?</h4>
            <div className="newsletter-survey__checkbox-container" style={{ gridTemplateColumns: 'repeat(3, 1fr)' }}>
              {interests.map(({ key, label }) => (
                <Checkbox
                  className="newsletter-survey__checkbox"
                  name={key}
                  value={surveyTags.includes(key)}
                  key={key}
                  onChange={e => onChangeTag(key, e)}
                  label={label}
                />
              ))}
            </div>
            <div className="newsletter-survey__select-container">
              {countries && (
                <Select
                  label="Select Country (Optional)"
                  options={countries?.map(c => ({ label: c.name, value: c.name }))}
                  name="country"
                  onChange={value => setSelectedCountry(value)}
                />
              )}
            </div>
            <div className="newsletter-survey__actions">
              <Button variant="tertiary" onPress={() => navigate('/en/donate/newsletter-thank-you')}>
                SKIP SURVEY
              </Button>
              <Button variant="primary" onPress={handleSubmit}>
                SUBMIT
              </Button>
            </div>
          </div>
        </section>
      </main>
    </Layout>
  );
};

export default Survey;
